/* eslint-disable camelcase */
import {
  Distributor,
  Filters,
  OrderParams,
  ReturnErrorResult,
  ReturnOrderItem,
  ReturnOrderProcessType,
  ReturnProduct,
} from "@page/ReturnOrder/interfaces";
import { StandardResponse } from "src/types";
import CoreAPI from "./CoreAPI";
import normalizeDistributionList from "./utils/normalizeDistributionList";
import normalizeReturnedOrders from "./utils/normalizeReturnedOrders";
import normalizeReturnOrderProducts, { normalizeReturnProduct } from "./utils/normalizeReturnOrderProducts";
import { transformAPIKeyToCamel } from "./utils/transformAPIKeyToCamel";
import { transformCamelToSnake } from "./utils/transformCamelToSnake";

const request = new CoreAPI();

interface ReturnOrderApi {
  fetchReturnOrderList: (filters: Filters) => Promise<ReturnOrderItem[]>;
  fetchReturnOrderProductList: (params: {
    orderId: number;
    limit: number;
    currentPage: number;
  }) => Promise<ReturnProduct[]>;
  fetchReturnOrderDetail: (orderId: number) => Promise<ReturnOrderItem>;
  addOrderProduct: (params: {
    orderId: number;
    qty: number;
    sku: string;
    effectiveDate: string;
  }) => Promise<ReturnProduct>;
  createReturnOrder: (params: OrderParams) => Promise<ReturnOrderItem>;
  createReturnOrderWithProduct: (params: OrderParams) => Promise<{ resultError: ReturnErrorResult[]; id: number }>;
  fetchDistributionList: () => Promise<Distributor[]>;
  exportReturnOrderListCSV: (filters: Filters) => Promise<any>;
  updateReturnOrder: (params: OrderParams) => Promise<ReturnOrderItem>;
  applyOrApproveReturnOrder: (orderId: number, processType: ReturnOrderProcessType) => Promise<StandardResponse>;
  approveReturnOrder: (orderId: number, isApproved: boolean) => Promise<StandardResponse>;
  deleteReturnOrder: (orderId: number) => Promise<StandardResponse>;
  deleteOrderProduct: (orderId: number, ladingLineId: number) => Promise<StandardResponse>;
  updateReturnProduct: (params: {
    orderId: number;
    ladingLineId: number;
    qty: number;
    sku: string;
    effectiveDate: string;
  }) => Promise<ReturnProduct>;
  importReturnProductXLS: (applicationId: number, file: File) => Promise<void>;
}

const returnOrderApi: ReturnOrderApi = {
  fetchReturnOrderList: (filters) => {
    const orderingPrefix = filters.sortOrder === "desc" ? "-" : "";
    const offset = filters.pageSize && filters.page ? filters.pageSize * (filters.page - 1) : 0;
    return request
      .getData("/manage/lading/ladings/", {
        estimated_date_after: filters.startEstimateShippingDate || undefined,
        estimated_date_before: filters.endEstimateShippingDate || undefined,
        shipped_date_after: filters.startActualShippingDate || undefined,
        shipped_date_before: filters.endActualShippingDate || undefined,
        lading_number: filters.orderNumber || undefined,
        sku: filters.sku || undefined,
        distributor: filters.marketingChannelId !== -1 ? filters.marketingChannelId : undefined,
        purpose: filters.reasonId !== -1 ? filters.reasonId : undefined,
        staff: filters.ordererId !== -1 ? filters.ordererId : undefined,
        offset,
        limit: filters.pageSize || 20,
        ordering: `${orderingPrefix}${filters.sortMethod}`,
      })
      .then((resp) => normalizeReturnedOrders(resp.data.result.results, resp.data.result.count));
  },
  fetchReturnOrderProductList: (params) => {
    const offset = params.limit && params.currentPage ? params.limit * (params.currentPage - 1) : 0;

    return request
      .getData(`/manage/lading/ladings/${params.orderId}/lading-lines/`, {
        offset,
        limit: params.limit || 20,
      })
      .then((resp) => {
        return normalizeReturnOrderProducts(resp.data.result.results, resp.data.result.count);
      });
  },
  fetchReturnOrderDetail: async (id) => {
    const response = await request.getData(`/manage/lading/ladings/${id}/`, {});
    const order = normalizeReturnedOrders([response.data.result], 1);
    return order[0];
  },
  addOrderProduct: (params) => {
    return request
      .postData(`/manage/lading/ladings/${params.orderId}/lading-lines/`, {
        sku: params.sku,
        qty: params.qty,
        effective_date: params.effectiveDate,
      })
      .then((resp) => {
        return normalizeReturnProduct(resp.data.result);
      });
  },
  createReturnOrder: (params) => {
    return request
      .postData("/manage/lading/ladings/", {
        estimated_shipping_date: params.estimatedShippingDate,
        purpose: params.reasonId,
        distributor: params.distributionId,
        recipient: params.receiver,
        recipient_tel: params.phone,
        address: `${params.city}${params.region}${params.address}`,
        zipcode: params.zipCode,
        description: params.comment,
        batch_number: params.batchNumber,
        only_record: params.onlyRecord,
        warehouse_id: params.warehouseId,
        charging_method: params.chargingMethod,
        estimated_arrived_date: params.estimatedArrivedDate,
      })
      .then((resp) => {
        const order = normalizeReturnedOrders([resp.data.result], 1);
        return order[0];
      });
  },
  createReturnOrderWithProduct: async (params) => {
    const { orderId, estimatedShippingDate, estimatedArrivedDate, comment } = params;
    const getParams = transformCamelToSnake({ estimatedShippingDate, estimatedArrivedDate, description: comment });
    const response = await request.postData(`/manage/lading/ladings/${orderId}/duplicate/`, getParams);
    return { resultError: transformAPIKeyToCamel(response.data).errorResult, id: response.data.result.id };
  },
  fetchDistributionList: () => {
    return request
      .getData("/manage/lading/distributors/", {})
      .then((resp) => normalizeDistributionList(resp.data.result.results));
  },
  exportReturnOrderListCSV: (filters) => {
    return request
      .getData("/manage/lading/ladings/csv-download/", {
        estimated_date_after: filters.startEstimateShippingDate || undefined,
        estimated_date_before: filters.endEstimateShippingDate || undefined,
        shipped_date_after: filters.startActualShippingDate || undefined,
        shipped_date_before: filters.endActualShippingDate || undefined,
        lading_number: filters.orderNumber || undefined,
        distributor: filters.marketingChannelId !== -1 ? filters.marketingChannelId : undefined,
        purpose: filters.reasonId !== -1 ? filters.reasonId : undefined,
        staff: filters.ordererId !== -1 ? filters.ordererId : undefined,
      })
      .then((res) => res);
  },
  updateReturnOrder: (params) => {
    return request
      .patchData(`/manage/lading/ladings/${params.orderId}/`, {
        estimated_shipping_date: params.estimatedShippingDate,
        purpose: params.reasonId,
        distributor: params.distributionId,
        recipient: params.receiver,
        recipient_tel: params.phone,
        address: params.address,
        zipcode: params.zipCode,
        description: params.comment,
        submitted: params.submitted,
        batch_number: params.batchNumber,
        warehouse_id: params.warehouseId,
        charging_method: params.chargingMethod,
        estimated_arrived_date: params.estimatedArrivedDate,
      })
      .then((resp) => {
        const order = normalizeReturnedOrders([resp.data.result], 1);
        return order[0];
      });
  },
  applyOrApproveReturnOrder: async (orderId, processType) => {
    const response = await request.putData(`/manage/lading/ladings/${orderId}/process/`, {
      process_type: processType,
    });
    return response.data;
  },
  approveReturnOrder: async (orderId, isApproved) => {
    const response = await request.putData(`/manage/lading/ladings/${orderId}/approve/`, {
      is_approved: isApproved,
    });
    return response.data;
  },
  deleteReturnOrder: async (orderId) => {
    const response = await request.deleteData(`/manage/lading/ladings/${orderId}/`, {});
    return response.data;
  },
  deleteOrderProduct: async (orderId, ladingLineId) => {
    const response = await request.deleteData(`/manage/lading/ladings/${orderId}/lading-lines/${ladingLineId}/`, {});
    return response.data;
  },
  updateReturnProduct: (params) => {
    return request
      .patchData(`/manage/lading/ladings/${params.orderId}/lading-lines/${params.ladingLineId}/`, {
        qty: params.qty,
        sku: params.sku,
        effective_date: params.effectiveDate,
      })
      .then((resp) => {
        return normalizeReturnProduct(resp.data.result);
      });
  },
  importReturnProductXLS: async (applicationId: number, file: File) => {
    const formData = new FormData();
    formData.append("file", file);
    const response = await request.postData(`/manage/lading/ladings/${applicationId}/lading-lines/upload/`, formData);
    return transformAPIKeyToCamel(response.data);
  },
};

export function cancelRequest() {
  request.cancelRequest();
}

export default returnOrderApi;
