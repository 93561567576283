import React from "react";
import styled from "styled-components";
import { Button, Col, Form, Row, Select, Input, DatePicker } from "antd";
import { onlineOrderState, setOrderListFilter, resetOrderFilter } from "@redux/onlineOrderSlice";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/store";
import moment from "moment";
import locale from "antd/es/date-picker/locale/zh_TW";
import { OrderStatus } from "@constant/OrderStatus";

const CustomRangePicker = styled(DatePicker.RangePicker)`
  width: 100%;
`;
const Wrapper = styled(Form)`
  padding: 24px 0px 0px 16px;
`;
const CustomButton = styled(Button)`
  margin-right: 10px;
`;

export default function ExternalOrderFilter() {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { orderListFilter } = useSelector(onlineOrderState);

  const onReset = () => {
    form.resetFields();
    dispatch(resetOrderFilter());
  };

  const onSubmit = (values: any) => {
    dispatch(
      setOrderListFilter({
        ...orderListFilter,
        orderNumber: values.orderNumber,
        platformNumber: values.platformNumber,
        sku: values.sku,
        platform: values.platform,
        shippingStatus: values.shippingStatus,
        estimatedShippingDateAfter: values.estimatedShippingDate
          ? moment(values.estimatedShippingDate[0]).format("YYYY-MM-DD")
          : undefined,
        estimatedShippingDateBefore: values.estimatedShippingDate
          ? moment(values.estimatedShippingDate[1]).format("YYYY-MM-DD")
          : undefined,
        shippingDateAfter: values.shippingDate ? moment(values.shippingDate[0]).format("YYYY-MM-DD") : undefined,
        shippingDateBefore: values.shippingDate ? moment(values.shippingDate[1]).format("YYYY-MM-DD") : undefined,
        createdAt: undefined,
        shippingAt: undefined,
      }),
    );
  };

  return (
    <Wrapper colon={false} labelAlign="left" form={form} initialValues={{}} onFinish={onSubmit}>
      <Row gutter={30}>
        <Col span={8}>
          <Form.Item label="訂單編號" name="orderNumber">
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="平台訂單編號" name="platformNumber">
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="品號" name="sku">
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={30}>
        <Col span={10}>
          <Form.Item label="預計出貨時間" name="estimatedShippingDate">
            <CustomRangePicker locale={locale} />
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item label="實際出貨時間" name="shippingDate">
            <CustomRangePicker locale={locale} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={30}>
        <Col span={5}>
          <Form.Item label="通路平台" name="platform">
            <Select defaultValue="-100">
              <Select.Option value="-100" disabled>
                請選擇
              </Select.Option>
              <Select.Option value="B2B-PChome">B2B-PChome</Select.Option>
              <Select.Option value="B2B-博客來">B2B-博客來</Select.Option>
              <Select.Option value="B2B-Yahoo">B2B-Yahoo</Select.Option>
              <Select.Option value="B2B-MOMO">B2B-MOMO</Select.Option>
              <Select.Option value="B2C-PChome">B2C-PChome</Select.Option>
              <Select.Option value="B2C-康是美">B2C-康是美</Select.Option>
              <Select.Option value="B2C-誠品">B2C-誠品</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item label="訂單狀態" name="shippingStatus">
            <Select defaultValue="-100">
              <Select.Option value="-100" disabled>
                請選擇
              </Select.Option>
              <Select.Option value={OrderStatus.CANCEL}>取消訂單</Select.Option>
              <Select.Option value={OrderStatus.WAITING_DEPLOY}>待拋單</Select.Option>
              <Select.Option value={OrderStatus.PROCESSING}>已拋單/待處理</Select.Option>
              <Select.Option value={OrderStatus.PICKING}>撿貨處理中</Select.Option>
              <Select.Option value={OrderStatus.OUT_OF_STOCK}>庫存不足</Select.Option>
              <Select.Option value={OrderStatus.ALREADY_SHIPPED}>配送中</Select.Option>
              <Select.Option value={OrderStatus.SHIPPING_ABNORMAL}>配送異常</Select.Option>
              <Select.Option value={OrderStatus.FINISH}>已送達</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={30}>
        <Col span={24}>
          <Row justify="end">
            <CustomButton onClick={onReset}>清除篩選條件</CustomButton>
            <Button type="primary" htmlType="submit">
              套用
            </Button>
          </Row>
        </Col>
      </Row>
    </Wrapper>
  );
}
