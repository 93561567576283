import { FilterTwoTone, PlusCircleTwoTone } from "@ant-design/icons";
import PageTitle from "@component/PageTitle";
import {
  fetchDistributions,
  fetchDownloadListCVS,
  fetchReturnOrderList,
  resetReturnOrderSlice,
  returnOrderState,
  setReturnOrderListFilter,
} from "@redux/returnOrderSlice";
import { Button, Col, Row, Select, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StyledOutlinedWrapper, StyledSection } from "src/styles/common";
import styled from "styled-components";
import { EditFormActionType } from "./constant";
import EditOrderForm from "./EditOrderForm";
import FilterForm from "./FilterForm";
import OrderFormProvider from "./order-form-provider";
import ReturnOrderList from "./ReturnOrderList";

const { Option } = Select;

const PageInfoText = styled(Space)`
  font-size: 14px;
`;

export default function ReturnOrderListPage() {
  const dispatch = useDispatch();
  const { returnOrderList, returnOrderListFilter, createOrderId } = useSelector(returnOrderState);

  const [newOrderForm, setNewOrderForm] = useState(false);
  const [openFilterForm, setOpenFilterForm] = useState(false);

  const handlePageSize = (pageSize: string) => {
    dispatch(
      setReturnOrderListFilter({
        pageSize: parseInt(pageSize, 10),
      }),
    );
  };

  const toggleFilterForm = () => {
    setOpenFilterForm(!openFilterForm);
  };

  const toggleNewOrderForm = () => {
    setNewOrderForm(!newOrderForm);
  };

  const downloadListCVS = () => {
    dispatch(fetchDownloadListCVS());
  };

  useEffect(() => {
    dispatch(fetchReturnOrderList());
  }, [dispatch, returnOrderListFilter]);

  useEffect(() => {
    if (createOrderId) {
      dispatch(fetchReturnOrderList());
    }
  }, [createOrderId, dispatch]);

  useEffect(() => {
    dispatch(fetchDistributions());
    return () => {
      dispatch(resetReturnOrderSlice());
    };
  }, [dispatch]);

  return (
    <div>
      <PageTitle title="還貨" />
      <StyledSection>
        <Row justify="space-between" style={{ marginBottom: 21 }} align="middle">
          <Col span={12}>
            <Button type="primary" icon={<PlusCircleTwoTone twoToneColor="#1890FF" />} onClick={toggleNewOrderForm}>
              新增還貨單
            </Button>
            <Button style={{ marginLeft: 10 }} icon={<FilterTwoTone />} onClick={toggleFilterForm}>
              篩選
            </Button>
            <Button style={{ marginLeft: 10 }} onClick={downloadListCVS}>
              匯出
            </Button>
          </Col>
          <Col span={12}>
            <Row justify="end">
              <PageInfoText>
                <span>{`總共${returnOrderList[0]?.totalCount || 0}筆，每頁顯示`}</span>
                <Select defaultValue="20" style={{ width: "72px", fontSize: "13px" }} onChange={handlePageSize}>
                  <Option value="20">20</Option>
                  <Option value="50">50</Option>
                  <Option value="100">100</Option>
                </Select>
                <span>筆</span>
              </PageInfoText>
            </Row>
          </Col>
        </Row>
        <OrderFormProvider>
          <FilterForm open={openFilterForm} />
          <EditOrderForm
            isModalVisible={newOrderForm}
            closeModal={() => setNewOrderForm(false)}
            action={EditFormActionType.NEW}
          />
          <StyledOutlinedWrapper>
            <ReturnOrderList />
          </StyledOutlinedWrapper>
        </OrderFormProvider>
      </StyledSection>
    </div>
  );
}
