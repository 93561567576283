import React from "react";
import styled from "styled-components";
import { OrderDetail } from "@api/throughShipment/onlineOrderApi";
import { OrderStatusLabel } from "@constant/OrderStatus";

const Wrapper = styled.div`
  padding-left: 16px;
  margin-top: 10px;
`;

const Row = styled.div`
  margin-bottom: 20px;
  display: flex;
`;
const RowTitle = styled.span`
  margin-right: 30px;
  width: 100px;
`;

type Props = {
  onlineOrderDetail: OrderDetail;
};

const warehouseMap: { [key: string]: string } = {
  RENTRAP: "藍田",
};

const OrderTab = (props: Props) => {
  const { onlineOrderDetail } = props;

  return (
    <Wrapper>
      <Row>
        <RowTitle>訂單編號</RowTitle>
        <span>{onlineOrderDetail.orderNumber}</span>
      </Row>
      <Row>
        <RowTitle>建立人員</RowTitle>
        <span>{onlineOrderDetail.staff}</span>
      </Row>
      <Row>
        <RowTitle>建立時間</RowTitle>
        <span>{onlineOrderDetail.createdAt}</span>
      </Row>
      <Row>
        <RowTitle>提交時間</RowTitle>
        <span>{onlineOrderDetail.submittedAt}</span>
      </Row>
      <Row>
        <RowTitle>訂單狀態</RowTitle>
        <span>{OrderStatusLabel[onlineOrderDetail.status]}</span>
      </Row>
      <Row>
        <RowTitle>出庫倉庫</RowTitle>
        <span>{warehouseMap[onlineOrderDetail.warehouseCode]}</span>
      </Row>
      <Row>
        <RowTitle>通路平台</RowTitle>
        <span>{onlineOrderDetail.platform}</span>
      </Row>
      <Row>
        <RowTitle>訂單總金額</RowTitle>
        <span>{onlineOrderDetail.grandTotal}</span>
      </Row>
      <Row>
        <RowTitle>預計出貨日</RowTitle>
        <span>{onlineOrderDetail.estimatedShippingDate}</span>
      </Row>
      <Row>
        <RowTitle>預計到貨日</RowTitle>
        <span>{onlineOrderDetail.estimatedArrivalDate}</span>
      </Row>
      <Row>
        <RowTitle>平台訂單編號</RowTitle>
        <span>{onlineOrderDetail.platformNumber}</span>
      </Row>
      <Row>
        <RowTitle>運送方式</RowTitle>
        <span>{onlineOrderDetail.shippingMethod}</span>
      </Row>
      <Row>
        <RowTitle>收件人</RowTitle>
        <span>{onlineOrderDetail.receiverName}</span>
      </Row>
      <Row>
        <RowTitle>收件電話</RowTitle>
        <span>{onlineOrderDetail.receiverPhone}</span>
      </Row>
      <Row>
        <RowTitle>收件地址</RowTitle>
        <span>
          {onlineOrderDetail.receiverZipcode}
          {onlineOrderDetail.receiverAddress}
        </span>
      </Row>
      <Row>
        <RowTitle>實際出貨時間</RowTitle>
        <span>{onlineOrderDetail.estimatedShippingDate}</span>
      </Row>
      <Row>
        <RowTitle>運送編號</RowTitle>
        <span>{onlineOrderDetail.packageNumber}</span>
      </Row>
      <Row>
        <RowTitle>備註</RowTitle>
        <span>{onlineOrderDetail.note}</span>
      </Row>
    </Wrapper>
  );
};

export default OrderTab;
