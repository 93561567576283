/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReturnOrderReason, returnOrderReasonLabel } from "@constant/ReturnOrderReason";
import { ReviewStateLabel } from "@constant/ReviewState";
import { ShippingStatusLabel } from "@constant/ShippingStatus";
import { returnOrderState } from "@redux/returnOrderSlice";
import { Col, Modal, Row, Spin, Typography } from "antd";
import Link from "antd/lib/typography/Link";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { ChargingMethod, chargingMethodOptions } from "./constant";
import { ReturnOrderItem } from "./interfaces";
import ReviewStateTable from "./RevieStateTabel";

type ItemKeys = keyof ReturnOrderItem;
const NoticeText = styled.a`
  display: block;
  margin-top: 5px;
`;
const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const fieldsData: {
  label: string;
  dataKey: ItemKeys;
}[] = [
  {
    label: "訂單編號",
    dataKey: "orderNumber",
  },
  {
    label: "總成本",
    dataKey: "totalCost",
  },
  {
    label: "申請狀態",
    dataKey: "reviewState",
  },
  {
    label: "",
    dataKey: "reviewStateByPosition",
  },
  {
    label: "訂購人",
    dataKey: "ordererName",
  },
  {
    label: "訂購時間",
    dataKey: "createOrderDate",
  },
  {
    label: "預期拋單日期",
    dataKey: "estimatedShippingDate",
  },
  {
    label: "實際出貨時間",
    dataKey: "actualShippingDate",
  },
  {
    label: "預計到貨日",
    dataKey: "estimatedArrivedDate",
  },
  {
    label: "出貨單號",
    dataKey: "shipperNumber",
  },
  {
    label: "運送狀態",
    dataKey: "shipmentState",
  },
  {
    label: "配送編號",
    dataKey: "packageNumber",
  },
  {
    label: "訂購緣由",
    dataKey: "reasonId",
  },
  {
    label: "",
    dataKey: "warehouseId",
  },
  {
    label: "運送方式",
    dataKey: "chargingMethod",
  },
  {
    label: "通路/倉別",
    dataKey: "distributionId",
  },
  {
    label: "收件人",
    dataKey: "recipient",
  },
  {
    label: "收件電話",
    dataKey: "recipientTel",
  },
  {
    label: "收件地址",
    dataKey: "address",
  },
  {
    label: "",
    dataKey: "zipCode",
  },
  {
    label: "說明",
    dataKey: "description",
  },
];

export default function OrderDetails(props: { orderItem: ReturnOrderItem }) {
  const { orderItem } = props;

  const { distributionList, isFetching } = useSelector(returnOrderState);

  const info = () => {
    Modal.info({
      icon: null,
      okText: "我知道了",
      content: (
        <div>
          <FlexColumn>
            <Typography.Title level={5}>審核規則說明</Typography.Title>
            <Typography.Text strong>主管級送出申請的審核規則</Typography.Text>
            <Typography.Text>1. 3000 元內：不須任何人審核</Typography.Text>
            <Typography.Paragraph>2. 3000 元以上：送出申請後，需 CEO / 財務進行審核</Typography.Paragraph>
            <Typography.Text strong>非主管級送出申請的審核規則</Typography.Text>
            <Typography.Text>1. 3000 元內：送出申請後，一／二階主管審核</Typography.Text>
            <Typography.Text>
              1. 3000 元以上：送出申請後，除需一／二階主管審核之外， CEO / 財務也需要審核
            </Typography.Text>
          </FlexColumn>
        </div>
      ),
      onOk() {},
    });
  };

  const generateRow = (field: { label: string; dataKey: ItemKeys }) => {
    switch (field.dataKey) {
      case "reviewState": {
        const key = orderItem[field.dataKey];
        const statusString = orderItem.isSubmitted ? ReviewStateLabel[key] : "未送出審核";
        const onlyRecordString = orderItem.onlyRecord && key !== -1 ? "(此還貨單僅記錄不拋單)" : "";

        return (
          <Row key={field.dataKey} style={{ marginBottom: "27px" }}>
            <Col span={4}>{field.label}</Col>
            <Col span={12}>
              {statusString}
              <NoticeText onClick={info}>審核規則說明</NoticeText>
              {onlyRecordString}
            </Col>
          </Row>
        );
      }
      case "reviewStateByPosition": {
        const tableData = orderItem.reviewStateByPosition;
        return (
          <Row key={field.dataKey} style={{ marginBottom: "27px" }}>
            <Col span={4}>{field.label}</Col>
            <Col span={12}>
              <ReviewStateTable data={tableData} />
            </Col>
          </Row>
        );
      }
      case "zipCode":
        return null;
      case "address":
        return (
          <>
            {orderItem.reasonId === ReturnOrderReason.RETURN &&
            orderItem.chargingMethod === ChargingMethod.SELF_PICKUP ? (
              ""
            ) : (
              <Row key={field.dataKey} style={{ marginBottom: "27px" }}>
                <Col span={4}>{field.label}</Col>
                <Col span={12}>{`${orderItem.zipCode}${orderItem.address}`}</Col>
              </Row>
            )}
          </>
        );
      case "shipmentState": {
        const status = orderItem[field.dataKey];
        return (
          <Row key={field.dataKey} style={{ marginBottom: "27px" }}>
            <Col span={4}>{field.label}</Col>
            <Col span={12}>{ShippingStatusLabel[status]}</Col>
          </Row>
        );
      }

      case "reasonId":
        return (
          <Row key={field.dataKey} style={{ marginBottom: "27px" }}>
            <Col span={4}>{field.label}</Col>
            <Col span={12}>{returnOrderReasonLabel[orderItem.reasonId] || ""}</Col>
          </Row>
        );
      case "warehouseId":
        return (
          <>
            {orderItem.reasonId === ReturnOrderReason.RETURN && (
              <Row key={field.dataKey} style={{ marginBottom: "27px" }}>
                <Col span={4}>{field.label}</Col>
                <Col span={12}>{orderItem.warehouseId}退倉</Col>
              </Row>
            )}
          </>
        );
      case "chargingMethod":
        return (
          <Row key={field.dataKey} style={{ marginBottom: "27px" }}>
            <Col span={4}>{field.label}</Col>
            <Col span={12}>{chargingMethodOptions[orderItem.chargingMethod].label}</Col>
          </Row>
        );
      case "recipient":
        return (
          <>
            <Row key={field.dataKey} style={{ marginBottom: "27px" }}>
              <Col span={4}>
                {orderItem.reasonId === ReturnOrderReason.RETURN &&
                orderItem.chargingMethod === ChargingMethod.SELF_PICKUP
                  ? "取件人"
                  : "收件人"}
              </Col>
              <Col span={12}>{orderItem.recipient}</Col>
            </Row>
          </>
        );
      case "recipientTel":
        return (
          <>
            <Row key={field.dataKey} style={{ marginBottom: "27px" }}>
              <Col span={4}>
                {orderItem.reasonId === ReturnOrderReason.RETURN &&
                orderItem.chargingMethod === ChargingMethod.SELF_PICKUP
                  ? "取件人電話"
                  : "收件人電話"}
              </Col>
              <Col span={12}>{orderItem.recipientTel}</Col>
            </Row>
          </>
        );
      case "distributionId": {
        const distribution = distributionList.find((dt) => dt.id === orderItem.distributionId);
        return (
          <Row key={field.dataKey} style={{ marginBottom: "27px" }}>
            <Col span={4}>{field.label}</Col>
            <Col span={12}>{distribution?.name || ""}</Col>
          </Row>
        );
      }
      default:
        return (
          <Row key={field.dataKey} style={{ marginBottom: "27px" }}>
            <Col span={4}>{field.label}</Col>
            <Col span={12}>{orderItem[field.dataKey] ?? ""}</Col>
          </Row>
        );
    }
  };

  return orderItem ? (
    <Spin spinning={isFetching}>
      <section style={{ padding: "27px 22px" }}>{fieldsData.map(generateRow)}</section>
    </Spin>
  ) : null;
}
