import { ReturnOrderReason } from "@constant/ReturnOrderReason";
import { ReturnOrderSortMethod } from "@constant/ReturnOrderSortMethod";
import { ReviewState } from "@constant/ReviewState";
import { ShipmentState } from "@constant/ShipmentState";

export interface ReturnOrderContext {
  returnOrderList: ReturnOrderItem[];
  pageSetting: {
    pageSize: number;
    current: number;
    total: number;
  };
  applyFilters: (filters: Filters) => void;
  exportList: (orderNumber: number) => void;
  applyTableSort: (Filters: Filters) => void;
  handlePageChange: (currentPage: number) => void;
  isFetching: boolean;
  deleteOrder: (orderNumber: number) => void;
}

export interface ReturnOrderFormContext {
  returnReason: {
    label: string;
    value: ReturnOrderReason;
  }[];
  isFetching: boolean;
  updateReturnOrder: (params: OrderParams, callback: () => void) => void;
  createReturnOrder: (params: OrderParams, callback: (orderItem: ReturnOrderItem) => void) => void;
}

export interface ReturnOrderItem {
  id: number;
  orderNumber: string;
  shipmentState: ShipmentState;
  reasonId: number;
  reasonLabel: string;
  createOrderDate: string;
  estimatedShippingDate: string;
  actualShippingDate: string;
  orderedQty: number;
  totalCost: number;
  distributionId: number;
  distributionLabel: string;
  ordererId: number;
  ordererName: string;
  totalCount: number; // total order count
  reviewStateByPosition: ReviewStateByPosition;
  reviewState: ReviewState;
  recipient: string;
  recipientTel: string;
  shipperNumber: string;
  packageNumber: string;
  address: string;
  zipCode: string;
  batchNumber: string;
  description: string;
  isSubmitted: boolean;
  destroyed: boolean;
  onlyRecord: boolean;
  warehouseId: string;
  chargingMethod: number;
  estimatedArrivedDate: string | null;
}

export interface ReturnErrorResult {
  sku: string;
  errReason: number;
  msg: string;
}

export interface ReviewStateByPosition {
  ceo: ReviewState;
  manager: ReviewState;
  finance: ReviewState;
  sectionManager: ReviewState;
}

export interface Filters {
  orderNumber?: string;
  sku?: string;
  startEstimateShippingDate?: string;
  endEstimateShippingDate?: string;
  startActualShippingDate?: string;
  endActualShippingDate?: string;
  marketingChannelId?: number;
  reasonId?: number;
  ordererId?: number;
  sortMethod?: ReturnOrderSortMethod;
  sortOrder?: "asc" | "desc";
  page?: number;
  pageSize?: number;
}

export interface OrderParams {
  orderId: number;
  estimatedShippingDate?: string;
  reasonId?: number;
  receiver?: string;
  phone?: string;
  city?: string;
  region?: string;
  address?: string;
  batchNumber?: string;
  comment?: string;
  zipCode?: string;
  submitted?: boolean;
  distributionId?: number;
  onlyRecord?: boolean;
  warehouseId?: string;
  chargingMethod?: number;
  estimatedArrivedDate?: string;
}

export interface ReturnProduct {
  id: number;
  sku: string;
  name: string;
  cost: number;
  orderedQty: number;
  orderId?: number;
  totalCount: number;
  canSalesQty: number;
  canReturnBadQty: number;
  effectiveDate?: string;
  errReason?: number[];
}

export interface Distributor {
  id: number;
  name: string;
}

export interface ReturnOrderDetail {
  id: number;
  estimatedShippingDate: string;
  shippedDate: string;
  staff: {
    id: number;
    name: string;
  };
  purpose: number;
  distributor: number;
  isSubmitted: boolean;
  totalCost: number;
  totalQty: number;
  status: number;
  description: string;
  ladingNumber: string;
  shippingStatus: number;
  address: string;
  zipcode: string;
  recipient: string;
  recipientTel: string;
  createdAt: string;
  sectionManagerApproval: number;
  managerApproval: number;
  ceoApproval: number;
  financeApproval: number;
}

export enum ReturnProductErrorStatus {
  DUPLICATE = 1,
  QTY_EXCEED = 2,
  OTHER = 3,
}

export interface ReturnProductErrorResult {
  sku: string;
  errReason: number;
  msg: string;
}

export enum ReturnOrderProcessType {
  APPLY = 1,
  APPROVE = 2,
  REJECT = 3,
}
