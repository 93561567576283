import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Modal, Table } from "antd";
import React from "react";
import styled from "styled-components";
import { ReturnProduct, ReturnProductErrorStatus } from "./interfaces";

const Flex = styled.div`
  display: flex;
  align-items: center;
`;
const EditText = styled(Button)`
  padding: 0;
`;
const DeleteText = styled(Button)`
  margin-left: 23px;
  padding: 0;
`;

export default function ReturnProductList(props: {
  list: ReturnProduct[];
  deleteProduct: (orderId: number, productId: number) => void;
  openProductForm: (product: ReturnProduct) => void;
  pagination: {
    limit: number;
    current: number;
  };
  isApplicationSended: boolean;
  onChange: (limit: any) => void;
}) {
  const { list, deleteProduct, openProductForm, pagination, isApplicationSended, onChange } = props;
  const excludedItems = isApplicationSended ? ["canSalesQty"] : []; // 還貨單送出申請就不會顯示可賣量欄位

  const columns: {
    title: string;
    dataIndex: keyof ReturnProduct;
    render?: any;
    width?: string | number;
  }[] = [
    { title: "ID", dataIndex: "id" },
    {
      title: "SKU",
      dataIndex: "sku",
      render: (sku: string, record: ReturnProduct) => {
        const { errReason } = record;
        return errReason && errReason.find((err) => err === ReturnProductErrorStatus.DUPLICATE) ? (
          <span style={{ color: "red" }}>{sku}</span>
        ) : (
          sku
        );
      },
    },
    { title: "商品名稱", dataIndex: "name" },
    { title: "成本", dataIndex: "cost" },
    {
      title: "庫存量",
      dataIndex: "canSalesQty",
    },
    { title: "商品效期", dataIndex: "effectiveDate" },
    {
      title: "商品數量(件)",
      dataIndex: "orderedQty",
      render: (qty: number, record: ReturnProduct) => {
        const { errReason } = record;
        return errReason && errReason.find((err) => err === ReturnProductErrorStatus.QTY_EXCEED) ? (
          <span style={{ color: "red" }}>{qty}</span>
        ) : (
          qty
        );
      },
    },
    {
      title: "",
      dataIndex: "orderId",
      width: 30,
      render: (orderId: number, record: ReturnProduct) => (
        <Flex>
          <EditText
            type="link"
            onClick={() => {
              openProductForm(record);
            }}
            disabled={isApplicationSended}
          >
            編輯
          </EditText>
          <DeleteText
            type="link"
            onClick={() => {
              Modal.confirm({
                title: "確定要刪除此商品嗎?",
                icon: <ExclamationCircleOutlined />,
                okText: "是",
                cancelText: "否",
                onOk() {
                  deleteProduct(orderId, record.id);
                },
              });
            }}
            disabled={isApplicationSended}
          >
            刪除
          </DeleteText>
        </Flex>
      ),
    },
  ];

  return (
    <Table
      columns={columns.filter((column) => !excludedItems.includes(column.dataIndex))}
      scroll={{ x: "max-content" }}
      dataSource={list}
      pagination={{
        pageSize: pagination.limit,
        current: pagination.current,
        showSizeChanger: false,
        total: list[0] && list[0].totalCount,
        onChange,
      }}
    />
  );
}
