/* eslint-disable camelcase */
import { ReturnProduct } from "@page/ReturnOrder/interfaces";
import { transformAPIKeyToCamel } from "./transformAPIKeyToCamel";

/**
 * 
 * @param response id: 3
lading: 11
product_info: {id: 15423, sku: "8057017530145", name: "[WACÙ] 少女款高級耐用真空袋1組2入(Size:M)", cost: 639}
qty: 2
 */
interface Item {
  id: number;
  lading: number;
  product_info: { id: number; sku: string; name: string };
  cost: number;
  qty: number;
  can_sales_qty: number;
  effective_date: string;
  err_reason: number[];
  can_return_bad_qty: number;
}

export default function normalizeReturnOrderProducts(response: Item[], totalCount: number): ReturnProduct[] {
  return response.map((ele: Item) =>
    transformAPIKeyToCamel({
      ...ele,
      totalCount,
      orderId: ele.lading,
      sku: ele.product_info.sku,
      name: ele.product_info.name,
      orderedQty: ele.qty,
    }),
  );
}

export function normalizeReturnProduct(item: Item): ReturnProduct {
  return transformAPIKeyToCamel({
    ...item,
    totalCount: 0,
    orderId: item.lading,
    sku: item.product_info.sku,
    name: item.product_info.name,
    orderedQty: item.qty,
  });
}
