import React, { useEffect, useState } from "react";
import { OrderStatus, OrderStatusLabel } from "@constant/OrderStatus";
import { FilterTwoTone, PlusCircleTwoTone } from "@ant-design/icons";
import PageTitle from "@component/PageTitle";
import { resetExternalOrderSlice } from "@redux/externalOrderSlice";
import { onlineOrderState, fetchOrderList, setOrderListFilter, fetchDeleteOrder } from "@redux/onlineOrderSlice";
import { Button, Row, Select, Table, Radio, Space, message, Modal } from "antd";
import { SelectValue } from "antd/lib/select";
import { ColumnsType } from "antd/lib/table";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/store";
import fontStyle from "src/styles/fontStyle";
import styled from "styled-components";
import ImportFileModal from "@component/ImportFileModal";
import { hideLoading, showLoading } from "@redux/notifySlice";
import onlineOrderApi, { OnlineOrderList } from "@api/throughShipment/onlineOrderApi";
import PopupBackground from "@component/PopupBackground";
import Filter from "./Filter";
import EditPopup from "./EditPopup";
import translateErrors from "./utils/translateErrors";

const Wrapper = styled.div`
  padding: 20px 20px 20px 25px;
`;
const PageText = styled.span`
  ${fontStyle("14px", "20px")};
`;
const SelectPageSize = styled(Select)`
  &&& {
    margin: 0 10px;
  }
`;
const TableWrapper = styled.div`
  border: solid 1px #f0f0f0;
  border-radius: 2px;
  padding: 20px 10px 22px 17px;
  margin-top: 20px;
`;
const CustomButton = styled(Button)`
  margin-right: 10px;
`;
const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 364px;

  padding: 30px 20px 20px 20px;
  background-color: white;
`;
const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 14px;
`;
const ButtonWrapper = styled(Row)`
  margin-top: 24px;
`;
const ModalContent = styled.section``;
const NoticeText = styled.p`
  margin-bottom: 15px;
`;

export default function OnlineOrder() {
  const { orderListFilter, onlineOrderListResult, isFetching } = useSelector(onlineOrderState);

  const dispatch = useAppDispatch();
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [showAddMethod, setShowAddMethod] = useState<boolean>(false);
  const [addMethod, setAddMethod] = useState<number>(1);
  const [showPopup, setShowPopup] = useState<boolean>(false);

  const setPageLimit = (value: SelectValue) => {
    dispatch(
      setOrderListFilter({
        ...orderListFilter,
        limit: parseInt(value as string, 10),
      }),
    );
  };

  const fetchPageChange = (value: number) => {
    setPage(value);
    dispatch(
      setOrderListFilter({
        ...orderListFilter,
        offset: (value - 1) * orderListFilter.limit,
      }),
    );
  };

  useEffect(() => {
    dispatch(fetchOrderList(orderListFilter));
  }, [dispatch, orderListFilter]);

  useEffect(() => {
    return () => {
      dispatch(resetExternalOrderSlice());
    };
  }, [dispatch]);

  const columns: ColumnsType<OnlineOrderList> = [
    {
      key: "orderNumber",
      dataIndex: "orderNumber",
      title: "訂單編號",
    },
    {
      key: "platform",
      dataIndex: "platform",
      title: "通路平台",
    },
    {
      key: "platformNumber",
      dataIndex: "platformNumber",
      title: "平台訂單編號",
    },
    {
      key: "status",
      dataIndex: "status",
      title: "訂單狀態",
      render: (data: OrderStatus, values) => {
        return <div>{OrderStatusLabel[data]}</div>;
      },
    },
    {
      key: "receiverName",
      dataIndex: "receiverName",
      title: "收件人",
    },
    {
      key: "totalEstimatedQty",
      dataIndex: "totalEstimatedQty",
      title: "預計出貨總數量(pcs)",
    },
    {
      key: "estimatedShippingDate",
      dataIndex: "estimatedShippingDate",
      title: "預計出貨日",
    },
    {
      key: "totalActualQty",
      dataIndex: "totalActualQty",
      title: "實際出貨總數量(pcs)",
    },
    {
      key: "shippingDate",
      dataIndex: "shippingDate",
      title: "實際出貨時間",
    },
    {
      key: "staff",
      dataIndex: "staff",
      title: "建立人員",
    },
    {
      key: "createdAt",
      dataIndex: "createdAt",
      title: "建立時間",
    },
    {
      key: "submittedAt",
      dataIndex: "submittedAt",
      title: "提交時間",
    },
    {
      key: "buttons",
      dataIndex: "buttons",
      title: "",
      render: (_, values) => {
        return (
          <Row justify="end">
            {values.destroyedAt || values.submittedAt ? (
              <Button type="link" onClick={() => window.open(`${window.location.href}/${values.id}/review`)}>
                檢視
              </Button>
            ) : (
              <Button type="link" onClick={() => window.open(`${window.location.href}/${values.id}`)}>
                編輯
              </Button>
            )}

            <Button type="link" onClick={() => handelDeleteOrder(values.id)} disabled={!!values.destroyedAt}>
              作廢
            </Button>
          </Row>
        );
      },
    },
  ];

  const handelDeleteOrder = (orderId: number) => {
    Modal.warn({
      title: "你確定要作廢這筆資料？",
      okText: "是",
      onOk() {
        dispatch(fetchDeleteOrder(orderId));
      },
      maskClosable: true,
    });
  };

  async function fetchBatchUploadOrderLists(file: File) {
    try {
      dispatch(showLoading());
      const response = await onlineOrderApi.fetchBatchUploadOrderLists(file);
      dispatch(fetchOrderList(orderListFilter));
      message.success("匯入成功");
      return response;
    } catch (error: any) {
      const { result } = error;
      const translatedErrors = translateErrors(result);
      if (!(translatedErrors.length > 0)) return null;
      Modal.warning({
        title: "部分商品資料有誤，請修正",
        content: (
          <ModalContent>
            {translatedErrors.map((errorItem: any) => {
              return <NoticeText>{errorItem}</NoticeText>;
            })}
          </ModalContent>
        ),
        okText: "我知道了",
      });

      return null;
    } finally {
      dispatch(hideLoading());
    }
  }

  function disableRow(value: OnlineOrderList) {
    return {
      className: value.destroyedAt ? "ur-disabled-text" : "",
    };
  }

  return (
    <Wrapper>
      <PageTitle title="線上出貨訂單" />
      <Row justify="space-between">
        <div>
          <CustomButton
            type="primary"
            icon={<PlusCircleTwoTone twoToneColor="#1890FF" />}
            onClick={() => setShowAddMethod(true)}
          >
            新增出貨單
          </CustomButton>
          <Button icon={<FilterTwoTone />} onClick={() => setShowFilter(!showFilter)}>
            篩選
          </Button>
        </div>
        <Row align="middle">
          <PageText>{`總共${onlineOrderListResult.count}筆, 每頁顯示`}</PageText>
          <SelectPageSize defaultValue="20" onChange={(value) => setPageLimit(value as SelectValue)}>
            <Select.Option value="20">20</Select.Option>
            <Select.Option value="50">50</Select.Option>
            <Select.Option value="100">100</Select.Option>
          </SelectPageSize>
          <PageText>筆</PageText>
        </Row>
      </Row>
      {showFilter && <Filter />}
      {showAddMethod && (
        <PopupBackground close={() => setShowAddMethod(false)}>
          <InnerWrapper>
            <Title>請選擇新增方式</Title>
            <Radio.Group value={addMethod} onChange={(e) => setAddMethod(e.target.value)}>
              <Space direction="vertical">
                <Radio value={1}>單筆新增(出貨單資料與商品分開新增)</Radio>
                <Radio value={2}>批次新增(出貨單資料與商品一起新增</Radio>
              </Space>
            </Radio.Group>
            <ButtonWrapper justify="end">
              <CustomButton onClick={() => setShowAddMethod(false)}>取消</CustomButton>
              <CustomButton
                type="primary"
                onClick={() => {
                  setShowPopup(true);
                  setShowAddMethod(false);
                }}
              >
                確認
              </CustomButton>
            </ButtonWrapper>
          </InnerWrapper>
        </PopupBackground>
      )}
      {showPopup && addMethod === 1 && <EditPopup onClose={() => setShowPopup(false)} />}
      {showPopup && addMethod === 2 && (
        <ImportFileModal
          csvErrorMessage=""
          closePopup={() => setShowPopup(false)}
          clearErrorMessage={() => {}}
          handleUploadFile={fetchBatchUploadOrderLists}
          templatePath="admin/template/xls/online_order_template.xlsx"
          fileType=".xlsx"
          noticeText="註：匯入上限為 1000 筆"
        />
      )}
      <TableWrapper>
        <Table
          loading={isFetching}
          scroll={{ x: "max-content" }}
          columns={columns}
          dataSource={onlineOrderListResult.results}
          pagination={{
            pageSize: orderListFilter.limit,
            current: page,
            showSizeChanger: false,
            total: onlineOrderListResult.count,
            onChange: fetchPageChange,
          }}
          onRow={disableRow}
        />
      </TableWrapper>
    </Wrapper>
  );
}
