import returnOrderApi from "@api/returnOrderApi";
import { ReturnOrderSortMethod } from "@constant/ReturnOrderSortMethod";
import { ChargingMethod } from "@page/ReturnOrder/constant";
import {
  Distributor,
  OrderParams,
  ReturnOrderItem,
  ReturnErrorResult,
  ReturnOrderProcessType,
} from "@page/ReturnOrder/interfaces";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import downloadCVS from "@utils/downloadCSV";
import { message, Modal, notification } from "antd";
import type { RootState } from "./rootReducer";

interface ReturnOrderListFilter {
  orderNumber?: string;
  sku?: string;
  startEstimateShippingDate?: string;
  endEstimateShippingDate?: string;
  startActualShippingDate?: string;
  endActualShippingDate?: string;
  marketingChannelId?: number;
  reasonId?: number;
  ordererId?: number;
  sortMethod?: ReturnOrderSortMethod;
  sortOrder?: "asc" | "desc";
  page: number;
  pageSize: number;
}

type ExtendedReturnOrderItem = ReturnOrderItem & {
  delete?: number;
};

interface IState {
  isFetching: boolean;
  returnOrderListFilter: ReturnOrderListFilter;
  returnOrderList: ExtendedReturnOrderItem[];
  distributionList: Distributor[];
  createOrderId?: number;
  isUpdateDone: boolean;
  isApplicationSended: boolean;
  returnOrderDetail?: ReturnOrderItem;
  errorResult?: ReturnErrorResult[];
}

export const initialState: IState = {
  isFetching: false,
  returnOrderListFilter: {
    sortOrder: "desc",
    sortMethod: ReturnOrderSortMethod.ByCreateOrderDate,
    page: 1,
    pageSize: 20,
  },
  returnOrderList: [],
  distributionList: [],
  createOrderId: undefined,
  isUpdateDone: false,
  isApplicationSended: false,
  returnOrderDetail: undefined,
  errorResult: [],
};

export const fetchReturnOrderList = createAsyncThunk("returnOrder/fetchReturnOrderList", async (_, thunkApi) => {
  const { returnOrderSlice } = thunkApi.getState() as RootState;
  try {
    const orders = await returnOrderApi.fetchReturnOrderList(returnOrderSlice.returnOrderListFilter);
    const withDeleteOrders = orders.map((order) => ({
      ...order,
      delete: order.id,
    }));
    return withDeleteOrders;
  } catch (err: any) {
    thunkApi.rejectWithValue(err.message);
    return err.message;
  }
});

export const fetchDeleteReturnOrder = createAsyncThunk(
  "returnOrder/fetchDeleteReturnOrder",
  async (orderId: number, thunkApi) => {
    try {
      await returnOrderApi.deleteReturnOrder(orderId);
      const { returnOrderSlice } = thunkApi.getState() as RootState;
      const updatedList = returnOrderSlice.returnOrderList.map((order) => {
        if (order.id === orderId) {
          return {
            ...order,
            destroyed: true,
          };
        }
        return order;
      });
      return updatedList;
    } catch (error: any) {
      message.error(`無法作廢還貨單 ${error.message}`);
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchDownloadListCVS = createAsyncThunk("returnOrder/fetchDownloadListCVS", async (_, thunkApi) => {
  try {
    const { returnOrderSlice } = thunkApi.getState() as RootState;
    const response = await returnOrderApi.exportReturnOrderListCSV(returnOrderSlice.returnOrderListFilter);
    downloadCVS(response);
  } catch (error: any) {
    Modal.error({
      title: `${error.message}`,
      content: "請確認還貨單是否已新增商品.",
    });
  }
});

export const fetchDistributions = createAsyncThunk("returnOrder/fetchDistributions", async (_, thunkApi) => {
  try {
    const response = await returnOrderApi.fetchDistributionList();
    return response;
  } catch (error: any) {
    message.error(`無法取得通路商列表 ${error.message}`);
    return thunkApi.rejectWithValue(error.message);
  }
});

export const fetchCreateReturnOrder = createAsyncThunk(
  "returnOrder/fetchCreateReturnOrder",
  async (params: OrderParams, thunkApi) => {
    try {
      const response = await returnOrderApi.createReturnOrder(params);
      if (params.chargingMethod) returnOrderAlertForDistribution(params.chargingMethod);
      return response;
    } catch (error: any) {
      message.error(`無法建立還貨單 ${error.message}`);
      return thunkApi.rejectWithValue(error.message);
    }
  },
);
export const createReturnOrderWithProduct = createAsyncThunk(
  "returnOrder/createReturnOrderWithProduct",
  async (params: OrderParams, thunkApi) => {
    try {
      const response = await returnOrderApi.createReturnOrderWithProduct(params);
      if (response.resultError) {
        thunkApi.dispatch(setErrorResult(response.resultError));
      }
      return response.id;
    } catch (error: any) {
      message.error(`無法建立還貨單 ${error.message}`);
      thunkApi.dispatch(setErrorResult([]));
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchUpdateReturnOrder = createAsyncThunk(
  "returnOrder/fetchUpdateReturnOrder",
  async (params: OrderParams, thunkApi) => {
    try {
      const response = await returnOrderApi.updateReturnOrder(params);
      thunkApi.dispatch(fetchOrderDetail(params.orderId));
      if (params.chargingMethod) returnOrderAlertForDistribution(params.chargingMethod);
      if (params.submitted) {
        message.success("您已成功送出申請");
      } else {
        message.success("編輯成功");
      }
      return response;
    } catch (error: any) {
      message.error(`無法建立還貨單 ${error.message}`);
      return thunkApi.rejectWithValue(error.message);
    }
  },
);
export const applyOrApproveReturnOrder = createAsyncThunk(
  "returnOrder/applyOrApproveReturnOrder",
  async (params: { orderId: number; processType: ReturnOrderProcessType }, thunkApi) => {
    try {
      const response = await returnOrderApi.applyOrApproveReturnOrder(params.orderId, params.processType);
      thunkApi.dispatch(fetchOrderDetail(params.orderId));
      switch (params.processType) {
        case ReturnOrderProcessType.APPLY:
          message.success("送出申請成功");
          break;
        case ReturnOrderProcessType.APPROVE:
        case ReturnOrderProcessType.REJECT:
          notification.open({
            message: "審查完成",
          });
          break;
        default:
      }
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchOrderDetail = createAsyncThunk("returnOrder/fetchOrderDetail", async (id: number) => {
  const response = await returnOrderApi.fetchReturnOrderDetail(id);
  return response;
});

const returnOrderSlice = createSlice({
  name: "returnOrder",
  initialState,
  reducers: {
    resetReturnOrderSlice: () => initialState,
    setReturnOrderListFilter: (state, action) => {
      state.returnOrderListFilter = { ...state.returnOrderListFilter, ...action.payload };
    },
    setCreateOrderId: (state, action) => {
      state.createOrderId = action.payload;
    },
    setIsUpdateDone: (state, action) => {
      state.isUpdateDone = action.payload;
    },
    setErrorResult: (state, action) => {
      state.errorResult = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchReturnOrderList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchReturnOrderList.fulfilled, (state, action) => {
      state.returnOrderList = action.payload;
      state.isFetching = false;
      state.createOrderId = undefined;
    });
    builder.addCase(fetchReturnOrderList.rejected, (state) => {
      state.isFetching = false;
    });
    builder.addCase(fetchDeleteReturnOrder.fulfilled, (state, action) => {
      state.returnOrderList = action.payload;
    });
    builder.addCase(fetchDistributions.fulfilled, (state, action) => {
      state.distributionList = action.payload;
    });
    builder.addCase(fetchCreateReturnOrder.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchCreateReturnOrder.fulfilled, (state, action) => {
      state.createOrderId = action.payload.id;
      state.isFetching = false;
    });
    builder.addCase(fetchCreateReturnOrder.rejected, (state) => {
      state.isFetching = false;
    });
    builder.addCase(fetchUpdateReturnOrder.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchUpdateReturnOrder.fulfilled, (state, action) => {
      state.isUpdateDone = true;
      state.isApplicationSended = action.payload.isSubmitted;
      state.returnOrderDetail = action.payload;
      state.isFetching = false;
    });
    builder.addCase(fetchUpdateReturnOrder.rejected, (state) => {
      state.isFetching = false;
    });
    builder.addCase(fetchOrderDetail.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchOrderDetail.fulfilled, (state, action) => {
      state.isApplicationSended = action.payload.isSubmitted;
      state.returnOrderDetail = action.payload;
      state.isUpdateDone = false;
      state.isFetching = false;
      state.createOrderId = undefined;
    });
    builder.addCase(fetchOrderDetail.rejected, (state) => {
      state.isFetching = false;
    });
    builder.addCase(createReturnOrderWithProduct.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(createReturnOrderWithProduct.fulfilled, (state, action) => {
      state.createOrderId = action.payload;
      state.isFetching = false;
    });
    builder.addCase(createReturnOrderWithProduct.rejected, (state) => {
      state.isFetching = false;
    });
  },
});

export const returnOrderState = (state: RootState) => state.returnOrderSlice;
export const {
  setReturnOrderListFilter,
  setCreateOrderId,
  setIsUpdateDone,
  resetReturnOrderSlice,
  setErrorResult,
} = returnOrderSlice.actions;
export default returnOrderSlice.reducer;

function returnOrderAlertForDistribution(chargingMethod: number) {
  if (chargingMethod === ChargingMethod.CHARTER) {
    Modal.warning({
      title: "提醒您，請記得發信 & Line通知明錩正確配送方式！",
      okText: "我知道了",
    });
  }
}
