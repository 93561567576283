import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { ReturnOrderReason } from "@constant/ReturnOrderReason";
import cityRegionData from "@utils/CityRegionData";
import {
  createReturnOrderWithProduct,
  fetchCreateReturnOrder,
  fetchUpdateReturnOrder,
  returnOrderState,
  setCreateOrderId,
  setIsUpdateDone,
} from "@redux/returnOrderSlice";
import { Button, Checkbox, Col, DatePicker, Form, Input, message, Modal, Radio, Row, Select, Space, Spin } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { RadioChangeEvent } from "antd/lib/radio";
import { SelectValue } from "antd/lib/select";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import getPostCodeWithCVS from "@utils/getPostCodeWithCVS";
import { ChargingMethod, chargingMethodOptions, EditFormActionType } from "./constant";
import { ReturnOrderItem } from "./interfaces";
import { returnOrderReason } from "./reasonData";

enum DistributionList {
  OTHER = 9,
  URMART = 10,
  BLUE_TING = 14,
  URMART_GUANG_FU = 15,
}
const CustomRadioWrapper = styled(Radio.Group)`
  padding-top: 10px;
`;
const CustomRadio = styled(Radio)``;

const { Option } = Select;

const ModalWrapper = styled(Modal)`
  &.ant-modal {
    top: 50px;
  }
  && .ant-form-item-required.ant-form-item-no-colon::before {
    display: none;
  }
  && .ant-form-item-required.ant-form-item-no-colon::after {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
  }
`;
const Red = styled.span`
  color: red;
`;

const NoticeText = styled.div`
  color: ${({ theme }) => theme.colorSecondary500};
`;

EditOrderForm.defaultProps = {
  returnOrderDetail: {},
};

export default function EditOrderForm(props: {
  isModalVisible: boolean;
  closeModal: () => void;
  action: EditFormActionType;
  returnOrderDetail?: ReturnOrderItem;
}) {
  const { isModalVisible, closeModal, action, returnOrderDetail } = props;
  const dispatch = useDispatch();
  const { distributionList, isUpdateDone, createOrderId, isFetching } = useSelector(returnOrderState);

  const navigate = useNavigate();
  const { returnOrderId } = useParams();

  const [form] = Form.useForm<{
    estimatedShippingDate: moment.Moment | null;
    reasonId: number;
    distributionId: number;
    receiver: string;
    phone: string;
    city: string;
    region: string;
    address: string;
    batchNumber: string;
    comment: string;
    onlyRecord: boolean;
    warehouseId: string;
    chargingMethod: number;
    estimatedArrivedDate: moment.Moment | null;
  }>();

  const allCities = Object.keys(cityRegionData);
  const [isReturn, setIsReturn] = useState<boolean>(false);
  const [warehouseType, setWarehouseType] = useState<string>("");
  const [isBadItem, setIsBadItem] = useState<boolean>(false);
  const [isSelfPickUp, setIsSelfPickUp] = useState<boolean>(false);
  const [addressDisabled, setAddressDisabled] = useState<boolean>(false);
  const [chargingMethodDisabled, setChargingMethodDisabled] = useState<boolean>(false);
  const [copyProduct, setCopyProduct] = useState<boolean>(false);

  const renderRegionOptions = (city: string) => {
    if (!city || !cityRegionData[city]) {
      return [];
    }
    const allRegions = Object.keys(cityRegionData[city]);
    return allRegions.map((region) => (
      <Option value={region} key={region}>
        {region}
      </Option>
    ));
  };

  const fillOutReturnOrderForm = useCallback(
    (copy: boolean = false) => {
      if (!returnOrderDetail) return;
      const { city, town, address } = getPostCodeWithCVS(returnOrderDetail.address);
      form.setFieldsValue({
        ...returnOrderDetail,
        estimatedShippingDate: moment(returnOrderDetail.estimatedShippingDate),
        estimatedArrivedDate: returnOrderDetail.estimatedArrivedDate
          ? moment(returnOrderDetail.estimatedArrivedDate)
          : undefined,
        receiver: returnOrderDetail.recipient,
        phone: returnOrderDetail.recipientTel,
        city,
        region: town,
        address,
        comment: returnOrderDetail.description,
      });
      if (!copy) {
        form.setFieldsValue({
          ...returnOrderDetail,
          estimatedShippingDate: moment(returnOrderDetail.estimatedShippingDate),
          estimatedArrivedDate: returnOrderDetail.estimatedArrivedDate
            ? moment(returnOrderDetail.estimatedArrivedDate)
            : undefined,
          receiver: returnOrderDetail.recipient,
          phone: returnOrderDetail.recipientTel,
          city,
          region: town,
          address,
          comment: returnOrderDetail.description,
        });
      }
    },
    [form, returnOrderDetail],
  );

  const handleOk = () => {
    if (!returnOrderDetail) return;

    const formState = form.getFieldsValue();
    switch (action) {
      case "update":
        dispatch(
          fetchUpdateReturnOrder({
            ...formState,
            address: isSelfPickUp ? "" : `${formState.city}${formState.region}${formState.address}`,
            estimatedShippingDate: formState.estimatedShippingDate
              ? formState.estimatedShippingDate.format("YYYY-MM-DD")
              : "",
            submitted: false,
            orderId: returnOrderDetail.id,
            zipCode: isSelfPickUp ? "" : cityRegionData[formState.city][formState.region],
            warehouseId: isBadItem ? "壞品" : "良品",
            chargingMethod: formState.chargingMethod,
            estimatedArrivedDate: formState.estimatedArrivedDate
              ? formState.estimatedArrivedDate.format("YYYY-MM-DD")
              : "",
          }),
        );
        break;
      case "copy":
      default:
        if (copyProduct) {
          dispatch(
            createReturnOrderWithProduct({
              orderId: returnOrderDetail.id,
              estimatedShippingDate: formState.estimatedShippingDate
                ? formState.estimatedShippingDate.format("YYYY-MM-DD")
                : "",
              estimatedArrivedDate: formState.estimatedArrivedDate
                ? formState.estimatedArrivedDate.format("YYYY-MM-DD")
                : "",
              comment: formState.comment,
            }),
          );
          return;
        }
        // new
        dispatch(
          fetchCreateReturnOrder({
            ...formState,
            estimatedShippingDate: formState.estimatedShippingDate
              ? formState.estimatedShippingDate.format("YYYY-MM-DD")
              : "",
            orderId: returnOrderDetail.id,
            zipCode: isSelfPickUp ? "" : cityRegionData?.[formState.city]?.[formState.region],
            submitted: false,
            warehouseId: isBadItem ? "壞品" : "良品",
            chargingMethod: formState.chargingMethod,
            estimatedArrivedDate: formState.estimatedArrivedDate
              ? formState.estimatedArrivedDate.format("YYYY-MM-DD")
              : "",
          }),
        );
    }
  };

  const handleModalClose = useCallback(() => {
    form.resetFields();
    setIsReturn(false);
    setIsSelfPickUp(false);
    setAddressDisabled(false);
    setChargingMethodDisabled(false);
    setIsBadItem(false);
    setCopyProduct(false);
    closeModal();
  }, [form, closeModal]);

  const onEstimatedShippingDateChange = (date: moment.Moment | null) => {
    if (date) {
      form.setFieldsValue({
        estimatedShippingDate: date,
      });
    }
  };

  const onEstimatedArrivedDateChange = (date: moment.Moment | null) => {
    if (date) {
      form.setFieldsValue({
        estimatedArrivedDate: date,
      });
    }
  };

  const onReasonChange = (value: number) => {
    form.setFieldsValue({
      reasonId: value,
    });
    if (value === ReturnOrderReason.RETURN) {
      form.setFieldsValue({ chargingMethod: undefined });
      setIsReturn(true);
    } else {
      setIsReturn(false);
      setIsSelfPickUp(false);
      setWarehouseType("良品");
    }

    form.setFieldsValue({
      distributionId: undefined,
      city: undefined,
      region: undefined,
      address: undefined,
      phone: undefined,
      receiver: undefined,
    });
  };
  const onAreaChange = (type: "city" | "region", value: any) => {
    if (type === "city") {
      form.setFieldsValue({
        city: value,
      });
    } else if (type === "region") {
      form.setFieldsValue({
        region: value,
      });
    }
  };
  const onDistributionChange = (value: number) => {
    // 如果選饌元、藍田、門市則直接帶入預設資訊且不可更改
    switch (value) {
      case DistributionList.URMART: {
        const estimatedShippingDate = checkEstimatedShippingDate();
        form.setFieldsValue({
          distributionId: value,
          city: "台北市",
          region: "南港區",
          address: "三重路19-9號B棟6樓",
          phone: "0910147912",
          receiver: "饌元有限公司",
          estimatedShippingDate,
        });
        setAddressDisabled(true);
        break;
      }
      case DistributionList.BLUE_TING:
        form.setFieldsValue({
          distributionId: value,
          city: "桃園市",
          region: "蘆竹區",
          address: "榮安路7號B區 (藍田物流)",
          phone: "0970510523",
          receiver: "藍田物流",
        });
        setAddressDisabled(true);
        break;
      case DistributionList.URMART_GUANG_FU:
        form.setFieldsValue({
          chargingMethod: ChargingMethod.NO_CHARGE,
          distributionId: value,
          city: "台北市",
          region: "大安區",
          address: "忠孝東路四段310號",
          phone: "0277515350",
          receiver: "Urmart 門市 - 國館巨蛋店",
        });
        setAddressDisabled(true);
        setChargingMethodDisabled(true);
        break;
      default:
        form.setFieldsValue({
          distributionId: value,
          city: undefined,
          region: undefined,
          address: undefined,
          phone: undefined,
          receiver: undefined,
        });
        setAddressDisabled(false);
        setChargingMethodDisabled(false);
    }
  };

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, id } = event.target;
    switch (id) {
      case "ur-address":
        form.setFieldsValue({
          address: value,
        });
        break;
      case "ur-phone":
        form.setFieldsValue({
          phone: value,
        });
        break;
      case "ur-batchNumber":
        form.setFieldsValue({
          batchNumber: value,
        });
        break;
      case "ur-comment":
        form.setFieldsValue({
          comment: value,
        });
        break;
      case "ur-receiverName":
        form.setFieldsValue({
          receiver: value,
        });
        break;
      default:
        break;
    }
  };

  const onCheckboxChange = (event: CheckboxChangeEvent) => {
    form.setFieldsValue({
      onlyRecord: event.target.checked,
    });
  };

  const disabledDate = (inputDate: any) => {
    return inputDate.isBefore(moment().subtract(1, "days")) || inputDate > moment().add(0.5, "y");
  };

  const onWarehouseChange = (e: RadioChangeEvent) => {
    setWarehouseType(e.target.value);
  };

  const onDeliveryChange = (e: SelectValue) => {
    if (e === ChargingMethod.SELF_PICKUP) {
      setIsSelfPickUp(true);
    } else {
      setIsSelfPickUp(false);
    }
  };
  const onCopyProductChange = useCallback(
    (checked: boolean) => {
      setCopyProduct(checked);
      if (checked) {
        // 複製還貨商品
        // 欄位disabled & 重新帶入初始值 returnOrderDetail
        setChargingMethodDisabled(true);
        setAddressDisabled(true);
        fillOutReturnOrderForm(true);
      } else {
        // 不複製還貨商品
        // 欄位enabled
        setAddressDisabled(false);
        setChargingMethodDisabled(false);
      }
    },
    [fillOutReturnOrderForm],
  );

  useEffect(() => {
    if (warehouseType === "壞品") {
      setIsBadItem(true);
    } else {
      setIsBadItem(false);
    }
  }, [warehouseType]);

  useEffect(() => {
    setIsReturn(false);
    setIsSelfPickUp(false);
    if (returnOrderDetail) {
      fillOutReturnOrderForm();
      if (returnOrderDetail.reasonId === ReturnOrderReason.RETURN) {
        setIsReturn(true);
      }
    }
  }, [fillOutReturnOrderForm, returnOrderDetail, isModalVisible, onCopyProductChange]);

  useEffect(() => {
    if (isUpdateDone) {
      dispatch(setIsUpdateDone(false));
      handleModalClose();
    }
  }, [isUpdateDone, handleModalClose, dispatch]);

  useEffect(() => {
    if (createOrderId) {
      if (returnOrderId) {
        dispatch(setCreateOrderId(undefined));
        navigate(`/return-inventory/edit/${createOrderId}`);
        message.success("還貨單複製成功！");
      }
      handleModalClose();
    }
  }, [createOrderId, handleModalClose, navigate, returnOrderId, dispatch]);

  useEffect(() => {
    const formState = form.getFieldsValue();
    if (action === "update") {
      if (formState.reasonId === 10) {
        setIsReturn(true);
      }
    }
  }, [action, form]);

  return (
    <ModalWrapper
      title=""
      visible={isModalVisible}
      onCancel={() => handleModalClose()}
      footer={null}
      maskClosable={false}
    >
      <Form
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        labelAlign="left"
        layout="horizontal"
        initialValues={{
          estimatedShippingDate: null,
          reasonId: undefined,
          distributionId: undefined,
          chargingMethod: undefined,
          receiver: "",
          phone: "",
          city: "",
          region: "",
          address: "",
          batchNumber: "",
          comment: "",
          onlyRecord: false,
        }}
        onFinish={handleOk}
        colon={false}
      >
        <Spin spinning={isFetching}>
          {action === "copy" && (
            <Form.Item>
              <Checkbox
                onChange={(e: CheckboxChangeEvent) => {
                  const { checked } = e.target;
                  onCopyProductChange(checked);
                }}
                checked={copyProduct}
              >
                要一併複製還貨商品
              </Checkbox>
            </Form.Item>
          )}
          <Form.Item
            name="estimatedShippingDate"
            label="預期拋單日期"
            rules={[
              {
                required: true,
                message: "請填入預期拋單日期",
              },
            ]}
          >
            <DatePicker onChange={onEstimatedShippingDateChange} placeholder="請選擇日期" disabledDate={disabledDate} />
          </Form.Item>
          <Row align="middle">
            <Col span={8} push={8}>
              <Form.Item name="onlyRecord" label="" valuePropName="checked">
                <Checkbox onChange={onCheckboxChange} disabled={action === "update"}>
                  <span>記錄不拋單</span>
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name="estimatedArrivedDate"
            label="預計到貨日"
            extra={<NoticeText>*此資訊用於顯示倉庫備註中，僅供倉庫人員參考</NoticeText>}
          >
            <DatePicker onChange={onEstimatedArrivedDateChange} placeholder="請選擇日期" disabledDate={disabledDate} />
          </Form.Item>
          <Form.Item
            label="訂購緣由"
            name="reasonId"
            rules={[
              {
                required: true,
                message: "請填入訂購緣由",
              },
            ]}
            extra={
              <>
                <NoticeText>
                  *行銷推廣：行銷推廣 / 公關品 / 抽獎贈品 / 提品 / 試吃品請選擇此項目，並於說明補充用途
                </NoticeText>
                <NoticeText>*出貨：僅限客服補貨使用 </NoticeText>
                <NoticeText>*調出：限品運調貨／門市叫貨使用 </NoticeText>
              </>
            }
          >
            <Select
              placeholder="請選擇"
              onChange={onReasonChange}
              style={{ width: "15vw", maxWidth: 188 }}
              disabled={copyProduct}
            >
              {returnOrderReason.map((reason) => (
                <Option value={reason.value} key={reason.label}>
                  {reason.label}
                </Option>
              ))}
            </Select>
          </Form.Item>

          {isReturn && (
            <Form.Item label=" ">
              <CustomRadioWrapper name="warehouseId" defaultValue="良品" onChange={(e) => onWarehouseChange(e)}>
                <CustomRadio value="良品" defaultChecked>
                  良品
                </CustomRadio>
                <CustomRadio value="壞品">壞品</CustomRadio>
              </CustomRadioWrapper>
            </Form.Item>
          )}
          <Form.Item
            label="通路/倉別"
            name="distributionId"
            rules={[
              {
                required: true,
                message: "請填入通路/倉別",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("distributionId") !== "-1") {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("請填入訂購緣由"));
                },
              }),
            ]}
          >
            <Select placeholder="請選擇" onChange={onDistributionChange} style={{ width: 188 }} disabled={copyProduct}>
              {distributionList.map((distributor) => (
                <Option value={distributor.id} key={distributor.name}>
                  {distributor.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="運送方式"
            shouldUpdate={(prevValues, curValues) => prevValues.reasonId !== curValues.reasonId}
          >
            <Form.Item
              name="chargingMethod"
              noStyle
              rules={[
                {
                  required: true,
                  message: "請填入運送方式",
                },
              ]}
            >
              <Select
                placeholder="請選擇"
                style={{ width: 188 }}
                onChange={(e) => onDeliveryChange(e)}
                disabled={chargingMethodDisabled}
              >
                {isReturn ? (
                  <>
                    {[ChargingMethod.CASH_ON_DELIVERY, ChargingMethod.SELF_PICKUP].map((method) => {
                      const options = chargingMethodOptions[method];
                      return (
                        <Option value={options.value} key={options.label}>
                          {options.label}
                        </Option>
                      );
                    })}
                  </>
                ) : (
                  <>
                    {[
                      ChargingMethod.NO_CHARGE,
                      ChargingMethod.CHARTER,
                      ChargingMethod.HCT_CHARTER,
                      ChargingMethod.T_CAT,
                    ].map((method) => {
                      const options = chargingMethodOptions[method];
                      return (
                        <Option value={options.value} key={options.label}>
                          {options.label}
                        </Option>
                      );
                    })}
                  </>
                )}
              </Select>
            </Form.Item>
          </Form.Item>

          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.distributionId !== currentValues.distributionId || prevValues.city !== currentValues.city
            }
          >
            {({ getFieldValue }) => {
              const city = getFieldValue("city");
              return (
                <>
                  <Form.Item
                    name="receiver"
                    label={isSelfPickUp ? "取件人" : "收件人"}
                    rules={[
                      {
                        required: true,
                        message: isSelfPickUp ? "請填入取件人" : "請填入收件人",
                      },
                    ]}
                  >
                    <Input onChange={onInputChange} id="ur-receiverName" disabled={addressDisabled} />
                  </Form.Item>
                  <Form.Item
                    label={isSelfPickUp ? "取件人電話" : "收件人電話"}
                    name="phone"
                    rules={[
                      {
                        required: true,
                        message: isSelfPickUp ? "請填入取件人電話" : "請填入收件人電話",
                      },
                    ]}
                  >
                    <Input onChange={onInputChange} type="phone" id="ur-phone" disabled={addressDisabled} />
                  </Form.Item>

                  <Row>
                    <Col span={8}>
                      <span>收件地址</span>
                      {!isSelfPickUp && <Red>*</Red>}
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        wrapperCol={{ span: 24 }}
                        name="city"
                        rules={[
                          {
                            required: !isSelfPickUp && true,
                            message: "請填入縣市區域",
                          },
                          () => ({
                            validator(_, value) {
                              if (!value || !getFieldValue("city") || getFieldValue("city") !== "-1") {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error("請填入縣市"));
                            },
                          }),
                        ]}
                      >
                        <Select
                          onChange={(value) => onAreaChange("city", value)}
                          disabled={isSelfPickUp || addressDisabled}
                          style={{ width: "100%" }}
                        >
                          <Option disabled={getFieldValue("chargingMethod") === ChargingMethod.SELF_PICKUP} value="-1">
                            請選擇
                          </Option>
                          {allCities.map((cityName) => (
                            <Option value={cityName} key={cityName}>
                              {cityName}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        wrapperCol={{ span: 24 }}
                        name="region"
                        label=""
                        style={{ marginRight: 8 }}
                        rules={[
                          {
                            required: !isSelfPickUp && true,
                            message: "請填入縣市區域",
                          },
                          () => ({
                            validator(_, value) {
                              if (!value || !getFieldValue("region") || getFieldValue("region") !== "-1") {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error("請填入區域"));
                            },
                          }),
                        ]}
                      >
                        <Select
                          onChange={(value) => onAreaChange("region", value)}
                          disabled={isSelfPickUp || addressDisabled}
                          style={{ width: "100%" }}
                        >
                          <Option value="-1">請選擇</Option>
                          {renderRegionOptions(city)}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item
                    name="address"
                    wrapperCol={{ span: 16, offset: 8 }}
                    rules={[
                      {
                        required: !isSelfPickUp && true,
                        message: "請填入地址",
                      },
                    ]}
                  >
                    <Input onChange={onInputChange} id="ur-address" disabled={isSelfPickUp || addressDisabled} />
                  </Form.Item>
                </>
              );
            }}
          </Form.Item>
          <Form.Item
            label="說明"
            name="comment"
            rules={[
              {
                required: true,
                message: "說明最少5字，最多30字",
                max: 30,
                min: 5,
              },
            ]}
          >
            <Input onChange={onInputChange} id="ur-comment" placeholder="最少5字，最多30字" />
          </Form.Item>
          <Form.Item wrapperCol={{ span: 16, offset: 8 }}>
            <Row justify="end">
              <Space>
                <Button type="default" onClick={() => handleModalClose()}>
                  取消
                </Button>
                <Button type="primary" htmlType="submit">
                  確認
                </Button>
              </Space>
            </Row>
          </Form.Item>
        </Spin>
      </Form>
    </ModalWrapper>
  );
}

function checkEstimatedShippingDate() {
  /**
    moment().day(-7); // last Sunday (0 - 7)
    moment().day(0); // this Sunday (0)
    moment().day(7); // next Sunday (0 + 7)
    moment().day(10); // next Wednesday (3 + 7)
    moment().day(24); // 3 Wednesdays from now (3 + 7 + 7 + 7)
   */
  const currentDay = moment().day();
  if (currentDay <= 2) {
    return moment().day(4);
  }
  if (currentDay > 2 && currentDay <= 4) {
    return moment().day(4);
  }
  return moment().day(0 + 7 + 2);
}
