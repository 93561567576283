/* eslint-disable react-hooks/exhaustive-deps */
import MenuListValue from "@constant/MenuListValue";
import { USER_MENU_HISTORY } from "@constant/StorageKeyValue";
import { usePermission, StaffPermission } from "@hooks/usePermission";
import cookie from "@utils/Cookie";
import { Menu } from "antd";
import "antd/dist/antd.css";
import React, { FC, useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

const CustomSubMenu = styled(Menu.SubMenu)`
  background-color: ${({ theme }) => theme.colorNeutral150};
`;

const topLevelItem = ["PLATFORM", "BRAND_OPERATION", "FINANCE", "COMMON"];
const menuItems: {
  key: string;
  root: string;
  subMenu: string | string[] | undefined;
}[] = [
  {
    key: MenuListValue.SALES_STATISTICS,
    root: "/sales-statistics",
    subMenu: MenuListValue.PLATFORM,
  },
  {
    key: MenuListValue.MARKET_STATISTICS,
    root: "/market-statistics",
    subMenu: MenuListValue.PLATFORM,
  },
  {
    key: MenuListValue.MEMBER_COHORT,
    root: "/member-cohort",
    subMenu: MenuListValue.PLATFORM,
  },
  {
    key: MenuListValue.VIP_STATISTICS,
    root: "/vip-statistics",
    subMenu: undefined,
  },
  {
    key: MenuListValue.SALES_PAGE_CATEGORY,
    root: "/salespage-category",
    subMenu: [MenuListValue.PLATFORM, MenuListValue.SHELVES_DISPLAY],
  },
  {
    key: MenuListValue.SALES_PAGE,
    root: "/salespage",
    subMenu: [MenuListValue.PLATFORM, MenuListValue.SHELVES_DISPLAY],
  },
  {
    key: MenuListValue.MENU_MANAGEMENT,
    root: "/menu-mangement",
    subMenu: [MenuListValue.PLATFORM, MenuListValue.SHELVES_DISPLAY],
  },
  {
    key: MenuListValue.TOPIC,
    root: "/topic",
    subMenu: [MenuListValue.PLATFORM, MenuListValue.SHELVES_DISPLAY],
  },
  {
    key: MenuListValue.BRAND_PAGE,
    root: "/brand-page",
    subMenu: [MenuListValue.PLATFORM, MenuListValue.SHELVES_DISPLAY],
  },
  {
    key: MenuListValue.PROMOTION,
    root: "/promotion",
    subMenu: [MenuListValue.PLATFORM, MenuListValue.MARKETING_PROMOTION],
  },
  {
    key: MenuListValue.RULE,
    root: "/rule",
    subMenu: [MenuListValue.PLATFORM, MenuListValue.MARKETING_PROMOTION],
  },
  {
    key: MenuListValue.VIP_RULE,
    root: "/vip-rule",
    subMenu: [MenuListValue.PLATFORM, MenuListValue.MARKETING_PROMOTION],
  },
  {
    key: MenuListValue.VIP_GIFT,
    root: "/vip-gift",
    subMenu: [MenuListValue.PLATFORM, MenuListValue.SHELVES_DISPLAY, MenuListValue.VIP_PROMOTION_GIFT],
  },
  {
    key: MenuListValue.VIP_BULLETIN_BOARD,
    root: "/vip-buttetin-board",
    subMenu: [MenuListValue.PLATFORM, MenuListValue.SHELVES_DISPLAY, MenuListValue.VIP_PROMOTION_GIFT],
  },
  {
    key: MenuListValue.SLOT_MANAGEMENT,
    root: "/slot-management",
    subMenu: [MenuListValue.PLATFORM, MenuListValue.MARKETING_PROMOTION],
  },
  {
    key: MenuListValue.VIP_PROMOTION,
    root: "/vip-promotion",
    subMenu: [MenuListValue.PLATFORM, MenuListValue.MARKETING_PROMOTION],
  },
  {
    key: MenuListValue.DISCOUNT_FRAME,
    root: "/discount-frame",
    subMenu: [MenuListValue.PLATFORM, MenuListValue.MARKETING_PROMOTION],
  },
  {
    key: MenuListValue.PRODUCT_CATEGORY,
    root: "/product-category",
    subMenu: [MenuListValue.PLATFORM, MenuListValue.PRODUCT_MANAGEMENT],
  },
  {
    key: MenuListValue.PRODUCT,
    root: "/product",
    subMenu: [MenuListValue.PLATFORM, MenuListValue.PRODUCT_MANAGEMENT],
  },
  {
    key: MenuListValue.CONTRACT,
    root: "/contract",
    subMenu: [MenuListValue.PLATFORM, MenuListValue.PRODUCT_MANAGEMENT],
  },
  {
    key: MenuListValue.SHELF_LIFE_MANAGEMENT,
    root: "/shelf-life-management",
    subMenu: [MenuListValue.PLATFORM, MenuListValue.PRODUCT_MANAGEMENT],
  },
  {
    key: MenuListValue.STOCK_MANAGEMENT,
    root: "/stock-management",
    subMenu: [MenuListValue.PLATFORM, MenuListValue.PRODUCT_MANAGEMENT],
  },
  {
    key: MenuListValue.BRAND,
    root: "/brand",
    subMenu: [MenuListValue.PLATFORM, MenuListValue.PRODUCT_MANAGEMENT],
  },
  {
    key: MenuListValue.ATTRIBUTE_MANAGEMENT,
    root: "/attribute-management",
    subMenu: [MenuListValue.PLATFORM, MenuListValue.PRODUCT_MANAGEMENT],
  },
  {
    key: MenuListValue.VENDOR_LIST,
    root: "/vendor",
    subMenu: [MenuListValue.PLATFORM, MenuListValue.VENDOR],
  },
  {
    key: MenuListValue.SUPPLEMENT,
    root: "/supplement",
    subMenu: [MenuListValue.PLATFORM, MenuListValue.VENDOR],
  },
  {
    key: MenuListValue.REPORT_REGEN,
    root: "/report-regen",
    subMenu: [MenuListValue.PLATFORM, MenuListValue.VENDOR],
  },
  {
    key: MenuListValue.INTO_WAREHOUSE,
    root: "/into-warehouse",
    subMenu: MenuListValue.WAREHOUSE,
  },
  {
    key: MenuListValue.RETURN_INVENTORY,
    root: "/return-inventory",
    subMenu: MenuListValue.WAREHOUSE,
  },
  {
    key: MenuListValue.MEMBER_DATA,
    root: "/member-data",
    subMenu: [MenuListValue.PLATFORM, MenuListValue.MEMBER],
  },
  {
    key: MenuListValue.REVIEW_MANAGEMENT,
    root: "/review-management",
    subMenu: [MenuListValue.PLATFORM, MenuListValue.MEMBER],
  },
  {
    key: MenuListValue.ORDER_CONTENT,
    root: "/order-content",
    subMenu: [MenuListValue.PLATFORM, MenuListValue.ORDER],
  },
  {
    key: MenuListValue.REPLENISH_RETURN,
    root: "/replenish-return",
    subMenu: [MenuListValue.PLATFORM, MenuListValue.ORDER],
  },
  {
    key: MenuListValue.SYSTEM,
    root: "/system-environment",
    subMenu: MenuListValue.PLATFORM,
  },

  {
    key: MenuListValue.CUSTOMS_CLEARANCE,
    root: "/customs-clearance",
    subMenu: MenuListValue.COMMON,
  },
  {
    key: MenuListValue.RECOST,
    root: "/recost",
    subMenu: MenuListValue.COMMON,
  },
  // 品運
  {
    key: MenuListValue.BO_PRODUCT,
    root: "/operation-of-brand/product",
    subMenu: MenuListValue.BRAND_OPERATION,
  },
  {
    key: MenuListValue.BO_STOCK,
    root: "/operation-of-brand/stock",
    subMenu: MenuListValue.BRAND_OPERATION,
  },
  {
    key: MenuListValue.DEALER,
    root: "/operation-of-brand/dealer",
    subMenu: [MenuListValue.BRAND_OPERATION, MenuListValue.SHIPPING_PATH],
  },
  {
    key: MenuListValue.SUPPLY_CHAIN,
    root: "/operation-of-brand/supply-chain",
    subMenu: [MenuListValue.BRAND_OPERATION, MenuListValue.SHIPPING_PATH],
  },
  {
    key: MenuListValue.EXTERNAL_ORDER,
    root: "/operation-of-brand/external-order",
    subMenu: [MenuListValue.BRAND_OPERATION, MenuListValue.SHIPPING_PATH],
  },
  {
    key: MenuListValue.ONLINE_ORDER,
    root: "/operation-of-brand/online-order",
    subMenu: [MenuListValue.BRAND_OPERATION, MenuListValue.SHIPPING_PATH],
  },
  {
    key: MenuListValue.INTO_WAREHOUSE_BRAND,
    root: "/operation-of-brand/into-warehouse",
    subMenu: [MenuListValue.BRAND_OPERATION],
  },
  {
    key: MenuListValue.MANUFACTURER,
    root: "/operation-of-brand/manufacturer",
    subMenu: [MenuListValue.BRAND_OPERATION, MenuListValue.INTO_WAREHOUSE_BRAND],
  },
  // -------------
  // 財務
  {
    key: MenuListValue.INVOICE,
    root: "/invoice",
    subMenu: MenuListValue.FINANCE,
  },
  {
    key: MenuListValue.COOPERATION,
    root: "/cooperation",
    subMenu: MenuListValue.FINANCE,
  },
  {
    key: MenuListValue.COST_STATEMENT,
    root: "/cost-statement",
    subMenu: MenuListValue.FINANCE,
  },
  {
    key: MenuListValue.RETURN_LIST,
    root: "/return-list",
    subMenu: MenuListValue.FINANCE,
  },
  {
    key: MenuListValue.EXCESS_INVENTORY,
    root: "/excess-inventory",
    subMenu: MenuListValue.FINANCE,
  },
  {
    key: MenuListValue.SCRAP_INVENTORY,
    root: "/scrap-inventory",
    subMenu: MenuListValue.FINANCE,
  },
  {
    key: MenuListValue.ONLINE_DISTRIBUTOR_STATISTICS,
    root: "/online-distributor-statistics",
    subMenu: MenuListValue.FINANCE,
  },
  {
    key: MenuListValue.TRYALL_STOCK_MANAGEMENT,
    root: "/tryall-stock-management",
    subMenu: MenuListValue.FINANCE,
  },
  {
    key: MenuListValue.STATISTICS_DOWNLOAD,
    root: "/statistics-download",
    subMenu: MenuListValue.FINANCE,
  },
  {
    key: MenuListValue.ORDER_PAYMENT,
    root: "/fiance/order-payment",
    subMenu: MenuListValue.FINANCE,
  },
  // 品運
  {
    key: MenuListValue.BO_PRODUCT,
    root: "/operation-of-brand/product",
    subMenu: MenuListValue.BRAND_OPERATION,
  },
  {
    key: MenuListValue.BO_STOCK,
    root: "/operation-of-brand/stock",
    subMenu: MenuListValue.BRAND_OPERATION,
  },
  {
    key: MenuListValue.DEALER,
    root: "/dealer",
    subMenu: [MenuListValue.BRAND_OPERATION, MenuListValue.SHIPPING_PATH],
  },
  {
    key: MenuListValue.SUPPLY_CHAIN,
    root: "/supply-chain",
    subMenu: [MenuListValue.BRAND_OPERATION, MenuListValue.SHIPPING_PATH],
  },
  {
    key: MenuListValue.EXTERNAL_ORDER,
    root: "/external-order",
    subMenu: [MenuListValue.BRAND_OPERATION, MenuListValue.SHIPPING_PATH],
  },
  {
    key: MenuListValue.INTO_WAREHOUSE_BRAND,
    root: "/operation-of-brand/into-warehouse",
    subMenu: [MenuListValue.BRAND_OPERATION],
  },
  {
    key: MenuListValue.MANUFACTURER,
    root: "/operation-of-brand/manufacturer",
    subMenu: [MenuListValue.BRAND_OPERATION, MenuListValue.INTO_WAREHOUSE_BRAND],
  },
  {
    key: MenuListValue.PROCESSING_APPLICATION,
    root: "/operation-of-brand/processing-application",
    subMenu: [MenuListValue.BRAND_OPERATION],
  },
  {
    key: MenuListValue.PROCUREMENT,
    root: "/operation-of-brand/procurement",
    subMenu: [MenuListValue.BRAND_OPERATION],
  },
  // -------------
  {
    key: MenuListValue.DASHBOARD,
    root: "/",
    subMenu: MenuListValue.PLATFORM,
  },
];

const MenuList: FC = () => {
  const { pathname } = useLocation();

  const [financeAccess, orderAccess] = usePermission([
    StaffPermission.CAN_ACCESS_FINANCE_PAGE,
    StaffPermission.CAN_ACCESS_ORDER_PAGE,
  ]);
  const [selectedKey, setSelectedKey] = useState<string>("");

  // 只有第一次會需要
  const defaultOpenKey = useMemo(() => {
    const rootMenuItem = menuItems.find((item) => {
      return pathname.startsWith(item.root);
    });
    let pathMenuItem: string[];
    if (!rootMenuItem || !rootMenuItem.subMenu) {
      pathMenuItem = [];
    } else {
      pathMenuItem = Array.isArray(rootMenuItem.subMenu) ? rootMenuItem.subMenu : [rootMenuItem.subMenu];
    }
    const history = cookie.getItem(USER_MENU_HISTORY) || [];
    const newPathMenuItem = [...new Set(pathMenuItem.concat(history))];
    cookie.setItem(USER_MENU_HISTORY, newPathMenuItem);
    return newPathMenuItem;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubMenuOpen = (openKeys: any) => {
    const history = openKeys.filter((key: string) => topLevelItem.includes(key));
    cookie.setItem(USER_MENU_HISTORY, history);
  };

  useEffect(() => {
    const rootMenuItem = menuItems.find((item) => pathname.startsWith(item.root));
    const rootMenuItemKey = rootMenuItem?.key || "";
    setSelectedKey(rootMenuItemKey);
  }, [pathname]);

  return (
    <Menu selectedKeys={[selectedKey]} defaultOpenKeys={defaultOpenKey} mode="inline" onOpenChange={handleSubMenuOpen}>
      {/* 平台 */}
      <CustomSubMenu key={MenuListValue.PLATFORM} title="平台">
        <Menu.Item key={MenuListValue.DASHBOARD}>
          <Link to="/">首頁/全站Dashboard</Link>
        </Menu.Item>
        <Menu.Item key={MenuListValue.SALES_STATISTICS}>
          <Link to="/sales-statistics">銷售統計表</Link>
        </Menu.Item>
        <Menu.Item key={MenuListValue.MARKET_STATISTICS}>
          <Link to="/market-statistics">活動成效表</Link>
        </Menu.Item>
        <Menu.Item key={MenuListValue.MEMBER_COHORT}>
          <Link to="/member-cohort">會員 Cohort 分析表</Link>
        </Menu.Item>
        <Menu.Item key={MenuListValue.VIP_STATISTICS}>
          <Link to="/vip-statistics">黑卡會員分析表</Link>
        </Menu.Item>
        <Menu.SubMenu key={MenuListValue.SHELVES_DISPLAY} title="上架與陳列">
          <Menu.Item key={MenuListValue.SALES_PAGE}>
            <Link to="/salespage">銷售頁</Link>
          </Menu.Item>
          <Menu.Item key={MenuListValue.SALES_PAGE_CATEGORY}>
            <Link to="/salespage-category">分類頁</Link>
          </Menu.Item>
          <Menu.Item key={MenuListValue.MENU_MANAGEMENT}>
            <Link to="/menu-mangement">目錄管理頁</Link>
          </Menu.Item>
          <Menu.Item key={MenuListValue.TOPIC}>
            <Link to="/topic">主題館</Link>
          </Menu.Item>
          <Menu.Item key={MenuListValue.BRAND_PAGE}>
            <Link to="/brand-page">品牌頁</Link>
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.SubMenu key={MenuListValue.MARKETING_PROMOTION} title="行銷與活動">
          <Menu.Item key={MenuListValue.PROMOTION}>
            <Link to="/promotion">活動</Link>
          </Menu.Item>
          <Menu.Item key={MenuListValue.RULE}>
            <Link to="/rule">優惠案型</Link>
          </Menu.Item>
          <Menu.Item key={MenuListValue.VIP_RULE}>
            <Link to="/vip-rule">VIP 優惠</Link>
          </Menu.Item>
          <Menu.SubMenu key={MenuListValue.VIP_PROMOTION_GIFT} title="VIP贈品">
            <Menu.Item key={MenuListValue.VIP_GIFT}>
              <Link to="/vip-gift">VIP贈品</Link>
            </Menu.Item>
            <Menu.Item key={MenuListValue.VIP_BULLETIN_BOARD}>
              <Link to="/vip-bulletin-board">VIP推廣</Link>
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.Item key={MenuListValue.SLOT_MANAGEMENT}>
            <Link to="/slot-management">版位管理</Link>
          </Menu.Item>
          <Menu.Item key={MenuListValue.DISCOUNT_FRAME}>
            <Link to="/discount-frame">宣傳框</Link>
          </Menu.Item>
          <Menu.Item key={MenuListValue.VIP_PROMOTION}>
            <Link to="/vip-promotion">黑卡推廣頁</Link>
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.SubMenu key={MenuListValue.PRODUCT_MANAGEMENT} title="商品管理">
          <Menu.Item key={MenuListValue.PRODUCT}>
            <Link to="/product">商品</Link>
          </Menu.Item>
          <Menu.Item key={MenuListValue.CONTRACT}>
            <Link to="/contract">合約</Link>
          </Menu.Item>
          <Menu.Item key={MenuListValue.SHELF_LIFE_MANAGEMENT}>
            <Link to="/shelf-life-management">效期管理</Link>
          </Menu.Item>
          <Menu.Item key={MenuListValue.STOCK_MANAGEMENT}>
            <Link to="/stock-management">庫存變動數</Link>
          </Menu.Item>
          <Menu.Item key={MenuListValue.BRAND}>
            <Link to="/brand">品牌</Link>
          </Menu.Item>
          <Menu.Item key={MenuListValue.PRODUCT_CATEGORY}>
            <Link to="/product-category">品類</Link>
          </Menu.Item>
          <Menu.Item key={MenuListValue.ATTRIBUTE_MANAGEMENT}>
            <Link to="/attribute-management">屬性管理</Link>
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.SubMenu key={MenuListValue.VENDOR} title="廠商">
          <Menu.Item key={MenuListValue.VENDOR_LIST}>
            <Link to="/vendor/list">廠商列表</Link>
          </Menu.Item>
          <Menu.Item key={MenuListValue.SUPPLEMENT}>
            <Link to="/supplement">補單</Link>
          </Menu.Item>
          <Menu.Item key={MenuListValue.REPORT_REGEN}>
            <Link to="/report-regen">重跑</Link>
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.SubMenu key={MenuListValue.WAREHOUSE} title="出入庫">
          <Menu.Item key={MenuListValue.INTO_WAREHOUSE}>
            <Link to="/into-warehouse">進倉</Link>
          </Menu.Item>
          <Menu.Item key={MenuListValue.RETURN_INVENTORY}>
            <Link to="/return-inventory">還貨</Link>
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.SubMenu key={MenuListValue.MEMBER} title="會員">
          <Menu.Item key={MenuListValue.MEMBER_DATA}>
            <Link to="/member-data">會員資料</Link>
          </Menu.Item>
          <Menu.Item key={MenuListValue.REVIEW_MANAGEMENT}>
            <Link to="/review-management">評分評論</Link>
          </Menu.Item>
        </Menu.SubMenu>
        {orderAccess && (
          <Menu.SubMenu key={MenuListValue.ORDER} title="訂單">
            <Menu.Item key={MenuListValue.ORDER_CONTENT}>
              <Link to="/order-content">訂單內容</Link>
            </Menu.Item>
            <Menu.Item key={MenuListValue.REPLENISH_RETURN}>
              <Link to="/replenish-return">補退貨</Link>
            </Menu.Item>
          </Menu.SubMenu>
        )}
        <Menu.Item key={MenuListValue.SYSTEM}>
          <Link to="/system-environment">系統</Link>
        </Menu.Item>
      </CustomSubMenu>
      {/* 共用功能 */}
      <CustomSubMenu key={MenuListValue.COMMON} title="共用功能">
        <Menu.Item key={MenuListValue.CUSTOMS_CLEARANCE}>
          <Link to="/customs-clearance">報關</Link>
        </Menu.Item>
        <Menu.Item key={MenuListValue.RECOST}>
          <Link to="/recost">成本回壓</Link>
        </Menu.Item>
      </CustomSubMenu>
      {/* 品運 */}
      <CustomSubMenu key={MenuListValue.BRAND_OPERATION} title="品運">
        <Menu.Item key={MenuListValue.BO_PRODUCT}>
          <Link to="/operation-of-brand/product">商品</Link>
        </Menu.Item>
        <Menu.Item key={MenuListValue.BO_STOCK}>
          <Link to="/operation-of-brand/stock">庫存效期</Link>
        </Menu.Item>
        <Menu.Item key={MenuListValue.INTO_WAREHOUSE_BRAND}>
          <Link to="/operation-of-brand/into-warehouse">進倉</Link>
        </Menu.Item>
        <Menu.SubMenu key={MenuListValue.SHIPPING_PATH} title="通路出貨">
          <Menu.Item key={MenuListValue.DEALER}>
            <Link to="/operation-of-brand/dealer">通路/經銷商</Link>
          </Menu.Item>
          <Menu.Item key={MenuListValue.SUPPLY_CHAIN}>
            <Link to="/operation-of-brand/supply-chain">供應商管理</Link>
          </Menu.Item>
          <Menu.Item key={MenuListValue.EXTERNAL_ORDER}>
            <Link to="/operation-of-brand/external-order">出貨訂單管理</Link>
          </Menu.Item>
          <Menu.Item key={MenuListValue.ONLINE_ORDER}>
            <Link to="/operation-of-brand/online-order">線上出貨訂單</Link>
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.Item key={MenuListValue.MANUFACTURER}>
          <Link to="/operation-of-brand/manufacturer">製造商</Link>
        </Menu.Item>
        <Menu.Item key={MenuListValue.PROCESSING_APPLICATION}>
          <Link to="/operation-of-brand/processing-application">商品加工</Link>
        </Menu.Item>
        <Menu.Item key={MenuListValue.PROCUREMENT}>
          <Link to="/operation-of-brand/procurement">採購單</Link>
        </Menu.Item>
      </CustomSubMenu>
      {/* 財務 */}
      <CustomSubMenu key={MenuListValue.FINANCE} title="財務">
        {financeAccess && (
          <Menu.Item key={MenuListValue.INVOICE}>
            <Link to="/invoice">收入(發票)清單</Link>
          </Menu.Item>
        )}
        <Menu.Item key={MenuListValue.ORDER_PAYMENT}>
          <Link to="/fiance/order-payment">訂單請款清單</Link>
        </Menu.Item>
        <Menu.Item key={MenuListValue.COOPERATION}>
          <Link to="/cooperation">異業合作清單</Link>
        </Menu.Item>
        {financeAccess && (
          <Menu.Item key={MenuListValue.COST_STATEMENT}>
            <Link to="/cost-statement">成本清單</Link>
          </Menu.Item>
        )}
        <Menu.Item key={MenuListValue.RETURN_LIST}>
          <Link to="/return-list">退貨清單</Link>
        </Menu.Item>
        <Menu.Item key={MenuListValue.EXCESS_INVENTORY}>
          <Link to="/excess-inventory">存貨呆滯提列</Link>
        </Menu.Item>
        <Menu.Item key={MenuListValue.SCRAP_INVENTORY}>
          <Link to="/scrap-inventory">報廢</Link>
        </Menu.Item>
        <Menu.Item key={MenuListValue.ONLINE_DISTRIBUTOR_STATISTICS}>
          <Link to="/online-distributor-statistics">線上通路銷售報表</Link>
        </Menu.Item>
        <Menu.Item key={MenuListValue.TRYALL_STOCK_MANAGEMENT}>
          <Link to="/tryall-stock-management">藍田庫存變動數</Link>
        </Menu.Item>
        <Menu.Item key={MenuListValue.STATISTICS_DOWNLOAD}>
          <Link to="/statistics-download">報表下載</Link>
        </Menu.Item>
      </CustomSubMenu>
    </Menu>
  );
};

export default MenuList;
