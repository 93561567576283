import { transformAPIKeyToCamel } from "@api/utils/transformAPIKeyToCamel";

interface ErrorMessages {
  [key: string]: string;
}

const errorMessages: ErrorMessages = {
  duplicateProduct: "商品重複",
  effectiveDateInvalid: "效期不合法",
  orderHasDeployed: "訂單已拋單",
  productNotExist: "商品不存在",
  requiredFieldsNotExist: "必填欄位沒填",
  shippingArrivedDtInvalid: "預期出貨與預期到貨日異常",
  shippingNotExist: "配送方式不存在",
  stockNotEnough: "商品庫存不足",
  warehouseNotExist: "倉庫不存在",
};

const translateErrors = (errorObject: any) => {
  if (!errorObject) return [];
  const camelErrorObject = transformAPIKeyToCamel(errorObject);
  const translatedErrors: string[] = [];
  Object.keys(camelErrorObject).forEach((key) => {
    if (camelErrorObject[key].length > 0) {
      const translatedKey = errorMessages[key];
      if (translatedKey) {
        camelErrorObject[key].forEach((item: string) => {
          translatedErrors.push(`${translatedKey}: ${item}`);
        });
      }
    }
  });

  return translatedErrors;
};

export default translateErrors;
